import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ClubLogo } from "../../ClubLogo";

export const WinProbability = ({teams=['team1','team2'], probability}) => {
  useEffect (() => {
    
  });
  return (
    <div className='w-[340px]'>
        <div className="w-[100%] h-[30px] top-0 left-0 bg-[#d2ff00] font-anton text-[#18062f] font-normal text-[14px] pt-[8px] pl-[10px] border-solid border-[1px] border-[#18062f]">
            WIN PROBABILITY
        </div>
        <div className='bg-[#1b062f] w-[100%] pt-[10px] pb-[10px]'>
            <div className='flex'>
                <div className='w-[70px] text-center'>
                    <ClubLogo teamName={teams[0]}/>
                </div>
                <div className='flex-grow flex justify-between'>
                    <div style={{width: probability[0].probability*2+'%'}} > 
                        <div className='font-chakra text-[#f73261] text-[12px] pt-[10px] pb-[5px]'>{probability[0].probability}%</div>
                        <div className={`w-full h-[20px] bg-[#f73261]`} />
                    </div>
                    <div style={{width: (probability[1].probability*2)+'%'}} > 
                        <div className='font-chakra text-[#32f78c] text-[12px] pt-[10px] pb-[5px] text-right'>{probability[1].probability}%</div>
                        <div className={`w-full h-[20px] bg-[#32f78c]`} />
                    </div>
                </div>
                <div className='w-[70px] text-center'>
                    <ClubLogo teamName={teams[1]} />
                </div>
            </div>
            <div className='flex font-chakra font-normal text-[#fff] text-[12px]'>
                <div className='w-[50%] text-left p-[15px]'>{teams[0]}</div>
                <div className='w-[50%] text-right p-[15px]'>{teams[1]}</div>
            </div>
        </div>

    </div>
  );
};
export default WinProbability;